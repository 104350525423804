import React from "react"
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

import "./styling/Impressum.scss"

const Impressum = () => {
    return(
        <>
            <Navbar/>
                <div className="impressum">
                    <h1>Impressum</h1>
                    <div></div>
                    <h2 className="impressum-location">
                        D.I. Kafol GmbH<br/>
                        Paulasgasse 10/Haus 2,<br/>
                        1110 Wien Österreich
                    </h2>
                    <h2 className="impressum-info">
                        Unternehmensgegenstand:<br/>
                        Durchführung von Gas-, Wasser-, Heizungs- und Sanitärinstallationen<br/>
                        UID-Nummer: ATU38682109<br/>
                        Firmenbuchnummer: FN 132082 p<br/>
                        Tel.: +43 699 11606682<br/>
                        E-Mail: dikafol2@gmail.com Geschäftsführer: Irimus Vasile-David
                    </h2>
                    <p>Quelle: Erstellt mit dem Impressum Generator von <a href={"https://www.adsimple.at/"}>AdSimple®</a> Blog Marketing in Kooperation mit <a href={"https://www.aboutbusiness.at/"}>aboutbusiness.at</a></p>
                </div>
            <Footer/>
        </>
    )
}

export default Impressum
